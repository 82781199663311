import { createSelector } from 'reselect';

export const menusSelector = createSelector(
  (state) => state.user.menus,
  (state) => state.user.loadingMenu,
  (state) => state.user.isLoadMenuFailed,
  (menus, loadingMenu, loadMenuFailed) => ({
    menus,
    loadingMenu,
    loadMenuFailed,
  })
);

export const loggingSelector = createSelector(
  (state) => state.user.logging,
  (logging) => logging
);

export const permissionCodeSelector = createSelector(
  (state) => state.user.isLoadingPermissionCode,
  (state) => state.user.isLoadPermissionCodeDone,
  (state) => state.user.permissionCodes,
  (isLoading, isLoadDone, permissionCodes) => ({
    isLoading,
    isLoadDone,
    permissionCodes,
  })
);

export const ownerInfoSelector = createSelector(
  (state) => state.user.isLoadingOwnerInfo,
  (state) => state.user.ownerInfo,
  (isLoadingOwnerInfo, ownerInfo) => ({
    isLoadingOwnerInfo,
    ownerInfo,
  })
);

export const currentUserSelector = createSelector(
  (state) => state.user.currentUser,
  (state) => state.user.currentRestaurantId,
  (state) => state.user.email,
  (state) => state.rgUser.currentRgId,
  (state) => state.user.identity,
  (currentUser, currentRestaurantId, email, currentRgId, identity) => ({
    currentUser,
    currentRestaurantId,
    email,
    currentRgId,
    identity,
  })
);

export const currentEmployeeSelector = createSelector(
  (state) => state.user.currentEmployee,
  (currentEmployee) => currentEmployee
);

export const ownedRestaurantsSelector = createSelector(
  (state) => state.user.isLoadOwnRestaurantsSuccess,
  (state) => state.user.ownedRestaurants,
  (isLoadSuccess, ownedRestaurants) => ({ isLoadSuccess, ownedRestaurants })
);

export const restaurantGroupSelector = createSelector(
  (state) => state.user.restaurantGroupInfo,
  (state) => state.user.restaurantGroupDealContract,
  (state) => state.user.restaurantGroupGiftCardDealContract,
  (state) => state.user.isLoadRestaurantGroupInfoDone,
  (
    restaurantGroupInfo,
    restaurantGroupDealContract,
    restaurantGroupGiftCardDealContract,
    isLoadRestaurantGroupInfoDone
  ) => {
    let restaurantGroupLoyaltySetting = null;
    let restaurantGroupLAnnualDeal = null;
    let restaurantGroupPrepaidCardSetting = null;
    let restaurantGroupGiftCardContractAcitved = false;
    if (restaurantGroupInfo && restaurantGroupGiftCardDealContract) {
      restaurantGroupGiftCardContractAcitved = true;
    }
    if (restaurantGroupInfo && restaurantGroupDealContract) {
      let { commission_rate, is_active, currency, name, id, vendor_id, vendor_type, ...others } =
        restaurantGroupDealContract;
      restaurantGroupLoyaltySetting = restaurantGroupDealContract.deal_contract_setting || null;

      restaurantGroupLAnnualDeal =
        (restaurantGroupDealContract.deals || []).find((item) => {
          if (item.deal_type === 'annual_fee') {
            return true;
          }
          return false;
        }) || null;

      restaurantGroupPrepaidCardSetting = {
        commission_rate,
        is_active,
        currency,
        name,
        id,
        vendor_id,
        vendor_type,
        purchaseOptions: (restaurantGroupDealContract.deals || []).filter((item) => {
          if (item.deal_type === 'prepaid') {
            return true;
          }
          return false;
        }),
      };
    }

    return {
      restaurantGroupLoyaltySetting,
      restaurantGroupLAnnualDeal,
      restaurantGroupPrepaidCardSetting,
      isLoadRestaurantGroupInfoDone,
      restaurantGroupInfo: restaurantGroupInfo && restaurantGroupDealContract ? restaurantGroupInfo : null,
      restaurantGroupDealContract,
      restaurantGroupGiftCardContractAcitved,
    };
  }
);
