import React, { useState, useMemo } from 'react';
import styles from './Component.less';

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  const { label, value, placeholder, required, component, ...other } = props;
  const isOccupied = focus || (value && value.length !== 0);
  const labelClass = useMemo(() => {
    return isOccupied ? 'label as-label' : 'label as-placeholder';
  }, [isOccupied]);
  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div className={styles.FloatLabelComponent} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {React.createElement(component, { value, onChange: props.onChange, ...other })}
      <label className={labelClass}>
        {isOccupied ? label : placeholder || label} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInput;
