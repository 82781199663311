import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Step1 from './step1';
import StepHome, { ContactFormWhithShortLayout, GetADemoOnTop } from './step_home';
import { Step1WithEmailLandingIntroduction, Step1WithReferralIntroduction } from './step1';
import Step3 from './step3';
import { useDispatch } from 'react-redux';
import { actions as demoRequestActions } from '../../actions/demoRequest';
import commonStyles from './common.less';
import useGoogleAds from 'src/hooks/googleAdsHook';
import { mixpanel } from '../../third_party/Mixpanel';
// const StepList = [Step1, Step3];

const GetADemoGuide = ({
  forEmailLanding = false,
  triggerSource = 'bottom',
  showSkipNow = false,
  onSkip,
  onAfterSubmit,
  title,
  subTitle,
}) => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const nextStep = () => {
    if (onAfterSubmit) {
      onAfterSubmit && onAfterSubmit();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    dispatch({
      type: demoRequestActions.reset,
    });
  }, []);

  const StepList = useMemo(() => {
    if (forEmailLanding) {
      return [Step1WithEmailLandingIntroduction, Step3];
    }
    return [Step1, Step3];
  }, [forEmailLanding]);

  return (
    <div>
      {React.createElement(StepList[step], { triggerSource, showSkipNow, prevStep, nextStep, onSkip, title, subTitle })}
    </div>
  );
};

export default GetADemoGuide;

export const ReferralGuide = ({ onSkip, onAfterSubmit, title, subTitle }) => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const nextStep = () => {
    if (onAfterSubmit) {
      onAfterSubmit && onAfterSubmit();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    dispatch({
      type: demoRequestActions.reset,
    });
  }, []);

  return (
    <div style={{ backgroundColor: '#fffffff' }}>
      {React.createElement(Step1WithReferralIntroduction, { prevStep, nextStep, onSkip, title, subTitle })}
    </div>
  );
};

export const HomeGetADemoGuide = ({
  forEmailLanding = false,
  showSkipNow = false,
  onSkip,
  onAfterSubmit,
  title,
  subTitle,
  triggerSource = 'bottom',
  showOnModal = false,
  onCloseWhenshowOnModal = () => {},
}) => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const { googleAdsUTMData } = useGoogleAds();

  const nextStep = () => {
    if (onAfterSubmit) {
      onAfterSubmit && onAfterSubmit();
      showOnModal && onCloseWhenshowOnModal();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    dispatch({
      type: demoRequestActions.reset,
    });
  }, []);

  useEffect(() => {
    if (showOnModal) {
      mixpanel.track({
        enableTracking: true,
        name: 'website/googleAds',
        properties: {
          ...(googleAdsUTMData || {}),
        },
      });
    }
  }, [showOnModal]);

  return (
    <div>
      {showOnModal ? <div className={commonStyles.closeIconOnAsModal} onClick={onCloseWhenshowOnModal} /> : null}
      {React.createElement(StepHome, {
        triggerSource,
        showSkipNow: false,
        prevStep: () => {},
        nextStep,
        onSkip,
        title,
        subTitle,
      })}
    </div>
  );
};

export const TopGetADemoGuider = ({
  forEmailLanding = false,
  showSkipNow = false,
  onSkip,
  onAfterSubmit,
  title,
  subTitle,
  triggerSource = 'bottom',
  showOnModal = false,
  onCloseWhenshowOnModal = () => {},
}) => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const { googleAdsUTMData } = useGoogleAds();

  const nextStep = () => {
    if (onAfterSubmit) {
      onAfterSubmit && onAfterSubmit();
      showOnModal && onCloseWhenshowOnModal();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    dispatch({
      type: demoRequestActions.reset,
    });
  }, []);

  useEffect(() => {
    if (showOnModal) {
      mixpanel.track({
        enableTracking: true,
        name: 'website/googleAds',
        properties: {
          ...(googleAdsUTMData || {}),
        },
      });
    }
  }, [showOnModal]);

  return (
    <div>
      {React.createElement(GetADemoOnTop, {
        triggerSource,
        showSkipNow: false,
        prevStep: () => {},
        nextStep,
        onSkip,
        title,
        subTitle,
      })}
    </div>
  );
};
