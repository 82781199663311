const BASE = 'GET_A_DEMO';

export const actions = {
  submitStep1: `${BASE}_SUBMIT_STEP1`,
  submitStep2: `${BASE}_SUBMIT_STEP2`,
  reset: `${BASE}_RESET`,
};

export const submitStep1Request = (payload) => async (dispatch) => {
  dispatch({
    type: actions.submitStep1,
    payload: payload,
  });
};

export const submitStep2Request = (payload) => async (dispatch) => {
  dispatch({
    type: actions.submitStep2,
    payload: payload,
  });
};
