import React from 'react';
import { Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import Component from './Component';
import PhoneInput from '../InternationalPhoneInput';

export const FloatLabelInput = (props) => <Component {...props} component={Input} />;

export const FloatLabelSelect = (props) => (
  <Component
    {...props}
    component={Select}
    getPopupContainer={(triggerNode) => triggerNode.parentNode}
    suffixIcon={<CaretDownOutlined style={{ fontSize: 16, color: '#1D1B2E' }} />}
  />
);

export const FloatLabelPhoneInput = (props) => <Component {...props} component={PhoneInput} />;

export const FloatLabelOption = Select.Option;
