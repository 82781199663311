import React, { Fragment } from 'react';
export const facebookId = '617217227168256';
export const FacebookPixel = () => {
  let imgsrc = `https://www.facebook.com/tr?id=${facebookId}&ev=PageView&noscript=1`;
  return (
    <Fragment>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          <!-- Meta Pixel Code -->
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '617217227168256');
          fbq('track', 'PageView');
          <!-- End Meta Pixel Code -->
    `,
        }}
      ></script>
      <noscript>
        <img height="1" width="1" src={imgsrc} />
      </noscript>
    </Fragment>
  );
};

class FBEvents {
  trackGetADemo(eventName, propertiesObj) {
    // eslint-disable-next-line no-undef
    fbq &&
      fbq('trackCustom', eventName, {
        ...propertiesObj,
      });
  }
}

export const FBPixelEventTracker = new FBEvents();
