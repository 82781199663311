import { createSelector } from 'reselect';
import { createIntl, createIntlCache } from 'react-intl';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

export const intlSelector = createSelector(
  (state) => state.app.locale,
  (state) => state.app.messages,
  (locale, messages) => createIntl({ locale, messages }, cache)
);

export const localeSelector = createSelector(
  (state) => state.app.locale,
  (locale) => locale
);

export const appConfigSelector = createSelector(
  (state) => state.app.baseAppConfig,
  (appConfig) => appConfig
);

export const isInAppSelector = createSelector(
  (state) => state.app.isInApp,
  (isInApp) => isInApp
);

export const collapsedSelector = createSelector(
  (state) => state.app.collapsed,
  (collapsed) => collapsed
);

export const appReadySelector = createSelector(
  (state) => state.app.appReady,
  (appReady) => appReady
);

export const isSwitchingRestaurantSelector = createSelector(
  (state) => state.app.isSwitchingRestaurant,
  (isSwitching) => isSwitching
);

export const restaurantListSelector = createSelector(
  (state) => state.app.isLoadingRestaurantList,
  (state) => state.app.isLoadRestaurantListSuccess,
  (state) => state.app.restaurantList,
  (isLoading, isLoadSuccess, restaurantList) => ({ isLoading, isLoadSuccess, restaurantList })
);

export const isGroupUserSelector = createSelector(
  (state) => state.app.isGroupUser,
  (isGroupUser) => isGroupUser
);
