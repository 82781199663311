import React, { PropsWithChildren } from 'react';
import CSSStyle from './FrozenRatio.module.less';
export default function FrozenRatio(props) {
  return (
    <div className={[CSSStyle.frozen_ratio, props.className || ''].join(' ')}>
      <i className={[CSSStyle.holder, 'frozen-holder'].join(' ')} style={{ paddingTop: props.ratio * 100 + '%' }} />
      <div className={[CSSStyle.main_content, props.mainClassName || ''].join(' ')}>{props.children}</div>
    </div>
  );
}
