import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';
const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;

const TopMenu = {
  en: [
    {
      name: 'Products',
      link: '/products',
      children: [
        {
          name: 'Point of Sale',
          link: `${CONTEXT_PATH}/products/point-of-sale`,
        },
        {
          name: 'POS Handheld',
          link: `${CONTEXT_PATH}/products/handheld`,
        },
        {
          name: 'Tablet Ordering',
          link: `${CONTEXT_PATH}/products/tablet-ordering`,
        },
        {
          name: 'QR Code Ordering',
          link: `${CONTEXT_PATH}/products/qrcode-ordering`,
        },

        {
          name: 'Kiosk',
          link: `${CONTEXT_PATH}/products/kiosk-ordering`,
        },
        // {
        //   name: '3-in-1 SmartOrdering',
        //   link: '/products/3-in-1-smart-ordering',
        // },
        {
          name: 'Restaurant Loyalty',
          link: `${CONTEXT_PATH}/products/loyalty`,
        },
        {
          name: 'Online Ordering',
          link: `${CONTEXT_PATH}/products/online-ordering`,
        },
        {
          name: 'Third party integration',
          link: `${CONTEXT_PATH}/products/thirdparty-integration`,
        },
        {
          name: 'Waitlist',
          link: `${CONTEXT_PATH}/products/waitlist`,
        },
        {
          name: 'Reservation',
          link: `${CONTEXT_PATH}/products/restaurant-reservation-app`,
        },
        {
          name: `Get a Loan`,
          link: `https://youlend.us/apply/chowbus/en-us/welcome`,
          target: '_blank',
        },
        {
          name: 'RuTech Robot',
          link: `${CONTEXT_PATH}/products/rutech-robot`,
        },
      ],
    },
    {
      name: 'Business types',
      link: '/business-types',
      children: [
        {
          name: 'Full Service',
          link: `${CONTEXT_PATH}/business-types/full-service`,
        },
        {
          name: 'Quick Service',
          link: `${CONTEXT_PATH}/business-types/quick-service`,
        },
        {
          name: 'Hotpot',
          link: `${CONTEXT_PATH}/business-types/hot-pot`,
        },
        {
          name: 'Dim Sum',
          link: `${CONTEXT_PATH}/business-types/dim-sum-pos`,
        },
        {
          name: 'Korean BBQ',
          link: `${CONTEXT_PATH}/business-types/korean-bbq`,
        },
        {
          name: 'Boba Tea & Dessert',
          link: `${CONTEXT_PATH}/business-types/boba-tea-dessert`,
        },

        {
          name: 'Sushi & Ramen',
          link: `${CONTEXT_PATH}/business-types/sushi-ramen`,
        },
        {
          name: 'Cafes',
          link: `${CONTEXT_PATH}/business-types/cafes`,
        },
      ],
    },
    {
      name: 'Why Chowbus POS',
      link: '/why-chowbus-pos',
    },
    {
      name: 'Learn',
      link: '/blog',
    },
    {
      name: 'Pricing',
      link: '/pricing',
    },
  ],
  zh: [
    {
      name: '产品中心',
      link: '/products',
      children: [
        {
          name: 'POS',
          link: `${CONTEXT_PATH}/products/point-of-sale`,
        },
        {
          name: '手持POS',
          link: `${CONTEXT_PATH}/products/handheld`,
        },
        {
          name: '平板点单',
          link: `${CONTEXT_PATH}/products/tablet-ordering`,
        },
        {
          name: '扫码点单',
          link: `${CONTEXT_PATH}/products/qrcode-ordering`,
        },

        {
          name: '智能自助点餐机',
          link: `${CONTEXT_PATH}/products/kiosk-ordering`,
        },
        // {
        //   name: '3-in-1 SmartOrdering',
        //   link: '/products/3-in-1-smart-ordering',
        // },
        {
          name: '会员系统',
          link: `${CONTEXT_PATH}/products/loyalty`,
        },
        {
          name: '餐厅订餐网页',
          link: `${CONTEXT_PATH}/products/online-ordering`,
        },
        {
          name: '第三方平台集成',
          link: `${CONTEXT_PATH}/products/thirdparty-integration`,
        },
        {
          name: '等位系统',
          link: `${CONTEXT_PATH}/products/waitlist`,
        },
        {
          name: '预约',
          link: `${CONTEXT_PATH}/products/restaurant-reservation-app`,
        },
        {
          name: `申请贷款`,
          link: `https://youlend.us/apply/chowbus/en-us/welcome`,
          target: '_blank',
        },
        {
          name: `RuTech餐厅机器人`,
          link: `${CONTEXT_PATH}/products/rutech-robot`,
        },
      ],
    },
    {
      name: '餐厅类型',
      link: '/business-types',
      children: [
        {
          name: '全服务餐厅',
          link: `${CONTEXT_PATH}/business-types/full-service`,
        },
        {
          name: '快捷服务餐厅',
          link: `${CONTEXT_PATH}/business-types/quick-service`,
        },
        {
          name: '火锅店',
          link: `${CONTEXT_PATH}/business-types/hot-pot`,
        },
        {
          name: '早茶店/粤菜馆',
          link: `${CONTEXT_PATH}/business-types/dim-sum-pos`,
        },
        {
          name: '韩国烤肉',
          link: `${CONTEXT_PATH}/business-types/korean-bbq`,
        },
        {
          name: '奶茶店 & 甜品店',
          link: `${CONTEXT_PATH}/business-types/boba-tea-dessert`,
        },

        {
          name: '日料 & 拉面店',
          link: `${CONTEXT_PATH}/business-types/sushi-ramen`,
        },
        {
          name: '咖啡厅',
          link: `${CONTEXT_PATH}/business-types/cafes`,
        },
      ],
    },
    {
      name: '为什么选择我们',
      link: '/why-chowbus-pos',
    },
    {
      name: 'POS资讯',
      link: '/blog',
    },
    {
      name: '合作方案',
      link: '/pricing',
    },
  ],
};

export default TopMenu;
