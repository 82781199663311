import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import styles from './common.less';

const Embed_Calendar_URL = 'https://calendly.com/pos-cal';

const Step3 = ({ showSkipNow, onSkip }) => {
  return (
    <div className={styles.StepContainer}>
      <div className="pos-poster">
        <picture>
          <source type="image/webp" srcSet={`${process.env.ASSETS_PREFIX}/products/Key-photo-min.webp`} />
          <img src={`${process.env.ASSETS_PREFIX}/products/Key-photo-min.png`} width="100%" alt="Chowbus POS Poster" />
        </picture>
      </div>
      <div className="pos-description">
        <h1>Thank you! </h1>
        <p>
          Thanks for your interest in partnering with Chowbus! We're adding new partners everyday and we'll be in touch
          soon.
        </p>

        {/* <iframe frameBorder="0" src={Embed_Calendar_URL} width="100%" height="580" /> */}
        <div style={{ marginTop: 16 }}>
          <Row gutter={[16, 32]}>
            {/* {showSkipNow && (
              <Col xs={24} lg={12}>
                <Button className="action-button" onClick={onSkip}>
                  Skip Now
                </Button>
              </Col>
            )} */}
            {/* <Col xs={24} lg={12}>
              <Button type="primary" className="action-button">
                Confirm
              </Button>
            </Col> */}
          </Row>
        </div>
      </div>
    </div>
  );
};

export const Step3Referral = ({ showSkipNow, onSkip }) => {
  useEffect(() => {
    // setTimeout(() => {
    //   const targtIfram = 'getDemoFromResponseIframe';
    //   if (document.getElementById(targtIfram)) {
    //     document.body.removeChild(document.getElementById(targtIfram))
    //   }
    // }, 1000);
  }, []);
  return (
    <div className={styles.StepContainer}>
      <div className="pos-poster">
        <picture>
          <source type="image/webp" srcSet={`${process.env.ASSETS_PREFIX}/products/Key-photo-min.webp`} />
          <img src={`${process.env.ASSETS_PREFIX}/products/Key-photo-min.png`} width="100%" alt="Chowbus POS Poster" />
        </picture>
      </div>
      <div className="pos-description">
        <h1>Thank you! </h1>
        <p>Thank you for your interest in the Restaurant Referral Program!</p>
      </div>
    </div>
  );
};

export default Step3;
