import { GET, POST, PUT, DELETE } from '../utils/request';
export function sendGetADemoRequestToSF(data) {
  return POST(`/api/get-a-demo`, data, {
    noNeedLogin: true,
  });
}

export function sendReferralRequestToSF(data) {
  return POST(`/api/referral`, data, {
    noNeedLogin: true,
  });
}
