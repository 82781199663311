import React, { useState, useCallback, useMemo } from 'react';
import { Button, Col, Dropdown, Menu, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import generalMenus from '../../consts/generalMenu';
import Link from 'next/link';
import { getItemName } from '../../utils/utils';
import styles from './CollapsedMenuHeader.less';
import { switchLocale } from '../../actions/app';
import { useDispatch } from 'react-redux';
import GoogleTagManager from '../../third_party/GoogleTagManager';
import router from 'next/router';
import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';
const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;
import topmenu from 'src/consts/poswebsite_content/topmenu';

const GeneralMenus = ({ locale, selectedKeys, isLogged, ownerPageId }) => {
  const generalMenus = useMemo(() => {
    return topmenu[locale];
  }, [locale]);
  const onLoginRequest = useCallback(() => {
    GoogleTagManager.trackLogin();
    router.push(`/user/login`);
  }, []);

  return (
    <div className={styles.CollapsedMenu}>
      <div className="inner">
        <div className="align-right" style={{ marginBottom: 20 }}>
          <Button
            type="primary"
            shape="round"
            href={`/get-a-demo${ownerPageId === 'pos-web-site-home-page-enh' ? '?source=homepage_button' : ''}`}
            size="large"
          >
            {locale === 'zh' ? '联系我们' : 'Get a demo'}
          </Button>
          {isLogged ? (
            <Button shape="round" href="/dashboard" size="large" style={{ marginLeft: 20 }} locale={false}>
              Dashboard
            </Button>
          ) : (
            <Button shape="round" size="large" style={{ marginLeft: 20 }} onClick={onLoginRequest}>
              {locale === 'zh' ? '登录' : 'Login'}
            </Button>
          )}
        </div>
        <Menu mode="inline" selectedKeys={selectedKeys}>
          {generalMenus.map((menu) => {
            if (!menu.children) {
              return (
                <Menu.Item key={menu.name}>
                  <Link href={menu.link}>
                    <a target={menu.target || '_self'}>{getItemName(menu, locale)}</a>
                  </Link>
                </Menu.Item>
              );
            }

            return (
              <Menu.SubMenu key={menu.name} title={menu.name}>
                {menu.children.map((subMenu) => (
                  <Menu.Item key={subMenu.name}>
                    <Link href={subMenu.link}>
                      <a target={subMenu.target || '_self'}>{getItemName(subMenu, locale)}</a>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

const CollapsedMenuHeader = ({ locale, selectedKeys, isLogged, simple, ownerPageId }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dispatch = useDispatch();
  const switchDisplayLang = useCallback(() => {
    let targetLocal = locale === 'en' ? 'zh' : 'en';
    GoogleTagManager.trackSwitchLanguge(locale === 'en' ? 'zh' : 'en');
    dispatch(switchLocale(locale === 'en' ? 'zh' : 'en'));

    router.replace(
      {
        route: router.pathname,
        query: router.query,
      },
      router.asPath,
      { locale: targetLocal }
    );
  }, [locale]);

  const toggleMenu = () => {
    setDropdownVisible((prev) => !prev);
  };

  return (
    <div className={styles.CollapsedMenuHeader}>
      <Row justify="space-between">
        <Col flex="1">
          <Link href={`${CONTEXT_PATH}/`} key="logo">
            <a>
              <img
                src={`${process.env.ASSETS_PREFIX}/home/chowbus_horizontal_logo.png`}
                alt="logo"
                width={127}
                height={24}
                title="chowbus logo"
              />
            </a>
          </Link>
        </Col>
        <Col>
          <Button
            shape="circle"
            size="middle"
            type="primary"
            onClick={switchDisplayLang}
            className={styles.switchLocalButton}
          >
            {locale === 'en' ? '中' : 'EN'}
          </Button>
        </Col>
        {!simple && (
          <Col>
            <Dropdown
              overlay={
                <GeneralMenus
                  ownerPageId={ownerPageId}
                  locale={locale}
                  selectedKeys={selectedKeys}
                  isLogged={isLogged}
                />
              }
              placement="bottomRight"
              overlayClassName={styles.DropdownMenu}
              onVisibleChange={(visible) => setDropdownVisible(visible)}
              visible={dropdownVisible}
            >
              <Button type="text" size="large" onClick={toggleMenu}>
                <MenuOutlined />
              </Button>
            </Dropdown>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CollapsedMenuHeader;
