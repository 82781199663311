import React, { useCallback, useRef } from 'react';
import { Col, Row } from 'antd';
import styles from './common.less';
import { FormattedMessage } from 'react-intl';
import GoogleTagManager from '../../third_party/GoogleTagManager';
import FrozenRatio from 'src/components/FrozenRatio/FrozenRatio';
import useLocale from 'src/hooks/useLocale';
import { ContactForm, ContactReferralForm } from 'src/components/GetADemoGuide/step_home';
import UnoptimizedImage from '../UnoptimizedImage';
import { mixpanel } from '../../third_party/Mixpanel';
import { LogosOnBrandWall } from 'src/consts/poswebsite_content/home';

let clientsLogo = [
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/tigersugar.png`,
    alt: 'tigersugar',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/xiaolongkai.png`,
    alt: 'xiaolongkai',
    width: 164,
  },

  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/crafty_crab.png`,
    alt: 'craftycrab',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/jongrobbq.png`,
    alt: 'jongrobbq',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/kungfutea.png`,
    alt: 'kong_fu_cha',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/machimachi.png`,
    alt: 'machimachi',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/chao_cha.png`,
    alt: 'chao_cha.png',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/yuan_cha.png`,
    alt: 'yuan_cha',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/teamo.png`,
    alt: 'teamo',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/nan_huo_guo.png`,
    alt: 'nan_huo_guo',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/jiangnan.png`,
    alt: 'jiang_nan',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/xiang_tian_xiao.png`,
    alt: 'xiang_tian_xiao',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/ka_ji_ken.png`,
    alt: 'ka_ji_ken.png',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/lao_jiu_men.png`,
    alt: 'lao_jiu_men.png',
    width: 164,
  },
  {
    logo: `${process.env.ASSETS_PREFIX}/demo-logs/kyuramen.png`,
    alt: 'kyuramen',
    width: 164,
  },
];
const Step1 = (props) => {
  let { title, subTitle } = props;
  const locale = useLocale();
  let intersectionObserverRef = useRef(null);
  const demoFormInstrocution = demoInstrocution[locale];

  const getADemoRef = useCallback((node) => {
    const threshold = 0.2;
    if (node) {
      try {
        let options = {
          root: null,
          rootMargin: '0px',
          threshold: threshold,
        };

        let observer = new IntersectionObserver((entries) => {
          let entry = entries[0];
          if (entry && entry.isIntersecting && entry.intersectionRatio >= threshold) {
            GoogleTagManager.trackStartDemoForm();
            mixpanel.track({
              enableTracking: true,
              name: 'website/Start Demo Form',
            });
          }
        }, options);
        observer.observe(node);
        intersectionObserverRef.current = observer;
      } catch (e) {
        intersectionObserverRef.current?.disconnect();
        intersectionObserverRef.current = null;
      }
    } else {
      intersectionObserverRef.current?.disconnect();
      intersectionObserverRef.current = null;
    }
  }, []);

  return (
    <div className={styles.StepContainer_}>
      <div className={styles.StepContainer}>
        <div className="pos-poster">
          <div style={{ textAlign: 'center' }}>
            <picture>
              <source type="image/webp" srcSet={`${process.env.ASSETS_PREFIX}/products/Key-photo-min.webp`} />
              <img
                className="client-3-in-1-image"
                src={`${process.env.ASSETS_PREFIX}/products/Key-photo-min.png`}
                style={{ maxWidth: '100%' }}
                alt="Chowbus POS Poster"
              />
            </picture>
          </div>
          {/* ClientLogoWallet */}
          <div className={styles.StepContainer__}>
            <div className={styles.ClientLogoWallet} style={{ width: '100%' }}>
              <div className={styles.header}>
                <div className={styles.headerTitle}>
                  <span style={{ position: 'relative', zIndex: 2 }}> {demoFormInstrocution.leftLogoWalletTitle}</span>
                </div>
              </div>
              <div>
                <Row
                  gutter={[
                    { xs: 10, sm: 10, md: 10 },
                    { xs: 10, sm: 10, md: 10 },
                  ]}
                >
                  {LogosOnBrandWall.map((logoName, index) => {
                    let logo = `${process.env.ASSETS_PREFIX}/demo-new-logs/${logoName}`;
                    let alt = logoName;
                    let width = 164;
                    // let {  width } = info;
                    return (
                      <Col xs={6} md={6} lg={5} xxl={5} key={index}>
                        <FrozenRatio ratio={1}>
                          <div
                            className="client-logo-container"
                            style={{ backgroundColor: 'transparent', padding: '10px 10px' }}
                          >
                            <UnoptimizedImage
                              layout="fill"
                              className="client-logo-image"
                              src={logo}
                              alt={alt}
                              style={{ width: `${100}%`, maxHeight: '100%' }}
                            />
                          </div>
                        </FrozenRatio>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-description">
          <div className="pos-description-inner" ref={getADemoRef}>
            <div style={{ marginBottom: '24px' }} className="top-emphasis-description AAA">
              {demoFormInstrocution.top.map(({ title, subTitle }, index) => {
                return (
                  <React.Fragment key={index}>
                    {<h1 style={index === 0 ? { marginTop: 0 } : {}}>{title}</h1>}
                    {
                      <React.Fragment>
                        {subTitle.map((item, index) => {
                          return (
                            <p key={index} className="withCheckbox">
                              <img
                                src={`${process.env.ASSETS_PREFIX}/checkbox.png`}
                                alt={''}
                                style={{ width: `22px`, maxHeight: '100%', marginRight: '8px' }}
                              />
                              <span dangerouslySetInnerHTML={{ __html: item }}></span>
                            </p>
                          );
                        })}
                      </React.Fragment>
                    }
                  </React.Fragment>
                );
              })}
            </div>
            <ContactForm {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Step1WithIntroductionContent = {
  zh: [
    {
      title: '使用智能点餐系统节省人力',
      subItems: [
        { content: '智能自助点餐机' },
        { content: '平板点餐' },
        { content: ' 二维码点餐' },
        { content: '等位系统' },
      ],
    },
    {
      title: '增长并留存忠实顾客',
      subItems: [
        { content: '餐厅专属会员系统' },
        { content: '餐厅客户关系营销' },
        { content: '餐厅专属品牌App' },
        { content: '餐厅电子会员+实体礼卡' },
      ],
    },
    {
      title: '发展您的业务',
      subItems: [
        { content: '餐厅订餐网页' },
        { content: 'Chowbus外送服务' },
        { content: 'Chowbus外卖平台整合' },
        { content: '第三方外卖平台集成' },
      ],
    },
  ],
  en: [
    {
      title: 'Save Labor with Smart Ordering',
      subItems: [
        { content: 'Kiosk' },
        { content: 'Tablet Ordering' },
        { content: 'QR Code Ordering' },
        { content: 'Waitlist' },
      ],
    },
    {
      title: 'Grow & Retain Loyal Customers',
      subItems: [
        { content: 'Restaurant Loyalty Program' },
        { content: 'Restaurant CRM Marketing' },
        { content: 'Chowbus Marketplace Integration' },
        { content: 'Restaurant Digital & Physical Gift Cards' },
      ],
    },
    {
      title: 'Grow Your Business',
      subItems: [
        { content: 'Online Ordering' },
        { content: 'Chowbus Delivery Services' },
        { content: 'Chowbus Marketplace Integration' },
        { content: 'Third Party Delivery Integrations' },
      ],
    },
  ],
};

const Step1WithReferralIntroductionContent = {
  zh: {
    referringSuccessCondition: {
      title: '推荐新餐厅使用Chowbus POS享$500好礼',
      desc: `填写推荐表格，并成功推荐一家新餐厅，推荐人可获得$500，被推荐的新餐厅可以免费获取设备`,
      condition: [
        '新餐厅成功签约并使用Chowbus POS一个月后，您可兑换福利',
        '如果您推荐多家餐厅，您需提交多次表格，推荐奖励可以累计',
        '如一家新餐厅被多个人推荐，则该推荐奖励仅会发放给最先提交表格的人',
        '如果推荐餐厅和被推荐餐厅隶属于同一个老板，将不会算做有效推荐，无法获取$500推荐礼金',
        '被推荐的新餐厅最多可免费兑换价值$4200设备',
      ],
    },
    describe: [],
  },
  en: {
    referringSuccessCondition: {
      title: 'Get $500 when a restaurant you refer joins Chowbus POS',
      desc: `If you know any restaurants that aren’t using Chowbus POS, you can refer them and get a $500 referral bonus when they use Chowbus POS for 30 days.`,
      condition: [
        'Referrer can redeem the reward one month after the new restaurant successfully signs up and uses Chowbus POS.',
        'If you refer multiple restaurants, you need to submit the form multiple times; referral rewards can accumulate.',
        'If a new restaurant is recommended by multiple people, the referral reward will only be given to the person who submitted the form first.',
        'If the referrer and the referee are under the same owner, it will not be considered a valid referral, and the $500 referral bonus will not be applicable.',
      ],
    },
    describe: [
      `<p style="color:#E00051;">If your referral is successful, we will contact you to pay out the $500 referral bonus.</p>`,
    ],
  },
};

const demoInstrocution = {
  zh: {
    leftLogoWalletTitle: '美国最受欢迎的餐饮品牌都信任Chowbus',
    top: [
      {
        title: '专为餐厅定制的POS系统',
        subTitle: [
          '为您提供一站式餐饮解决方案',
          '行业领先的智能点餐方案帮您节省人工 (-$8000)、增加效率、提升营业额(+25%)',
          '24/7小时及时的售后服务加上上门安装培训保障您的无忧切换',
          '马上填表预约咨询，领取<span style="color:#e00051">上千美金优惠大礼包！</span>',
        ],
      },
    ],
  },
  en: {
    leftLogoWalletTitle: 'Trusted by Popular Restaurants in the US',
    top: [
      {
        title: 'POS Systems Built for Restaurants',
        subTitle: [
          'All in 1 POS Management Suite - No need for multiple systems',
          'Smart Ordering solutions - Save labor (-$8000), increase efficiency and grow sales(+25%)',
          '24/7 Instant Customer Support - Onsite installation & training, shift with ease',
          'Book a demo now and claim your <span style="color:#e00051">exclusive discount package worth thousands!</span>',
        ],
      },
    ],
  },
};

export const Step1WithEmailLandingIntroduction = (props) => {
  const locale = useLocale();
  let { title, subTitle } = props;
  const advancePoints = Step1WithIntroductionContent[locale];
  const demoFormInstrocution = demoInstrocution[locale];
  return (
    <div className={`${styles.StepContainer}`}>
      <div className="pos-poster withintroduction">
        {advancePoints.map(({ title, subItems }, sectionIndex) => {
          return (
            <div className="demo-instroduction-section" key={sectionIndex}>
              <div className="demo-instroduction-title">{title}</div>
              <div className="demo-instroduction-items">
                <ul className="keypoint-list">
                  {subItems.map(({ content }, itemIndex) => {
                    return (
                      <li className="keypoint-item" key={itemIndex}>
                        {content}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
      <div className="pos-description withintroduction~~">
        <div id="email-landing-demo-form" style={{ position: 'absolute', top: '-140px' }} />
        <div className="pos-description-inner">
          <div style={{ marginBottom: '24px' }} className="top-emphasis-description">
            {demoFormInstrocution.top.map(({ title, subTitle }, index) => {
              return (
                <React.Fragment key={index}>
                  {<h1 style={index === 0 ? { marginTop: 0 } : {}}>{title}</h1>}
                  {
                    <React.Fragment>
                      {subTitle.map((item, index) => {
                        return (
                          <p key={index} className="withCheckbox">
                            <img
                              src={`${process.env.ASSETS_PREFIX}/checkbox.png`}
                              alt={''}
                              style={{ width: `22px`, maxHeight: '100%', marginRight: '8px' }}
                            />
                            <span dangerouslySetInnerHTML={{ __html: item }} />
                          </p>
                        );
                      })}
                    </React.Fragment>
                  }
                </React.Fragment>
              );
            })}
          </div>
          <ContactForm {...props} leadSource={'Email (B2B)'} />
        </div>
      </div>
    </div>
  );
};

export const Step1WithReferralIntroduction = (props) => {
  const locale = useLocale();
  let { title, subTitle } = props;
  const referralIntroduction = Step1WithReferralIntroductionContent[locale];
  return (
    <div className={`${styles.StepContainer} step1WithReferralIntroduction`}>
      <div className="pos-poster referral-introduction">
        {referralIntroduction.title && <div className="referral-title">{referralIntroduction.title}</div>}
        {referralIntroduction.subTitle && <div className="referral-subtitle">{referralIntroduction.subTitle}</div>}
        <div>
          <div className="referral-title-emphase">{referralIntroduction.referringSuccessCondition.title}</div>
          <p>{referralIntroduction.referringSuccessCondition.desc}</p>
          <ul>
            {referralIntroduction.referringSuccessCondition.condition.map((item, index) => {
              return <li key={index} className="referral-sucess-point" dangerouslySetInnerHTML={{ __html: item }}></li>;
            })}
          </ul>
        </div>
        {referralIntroduction.describe.map((item, index) => {
          return <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>;
        })}
      </div>
      <div className="pos-description withintroduction">
        <div id="email-landing-demo-form" style={{ position: 'absolute', top: '-140px' }} />
        <h1 style={{ fontSize: '26px' }}>
          <FormattedMessage id="home.referral.form.title" />
        </h1>
        <ContactReferralForm {...props} leadSource={'referral form'} />
      </div>
    </div>
  );
};

export default Step1;
