import React, { useState, useRef, useEffect } from 'react';
import { reduce, tail, head } from 'lodash';
import { Input, Select, Tooltip } from 'antd';
import { AsYouType } from 'libphonenumber-js/min';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

const PhoneCountryMap = {
  US: {
    dialCode: '1',
    nationName: 'United States',
    abbr: 'USA',
    format: '(...) ...-....',
  },
  CA: {
    dialCode: '1',
    nationName: 'Canada',
    abbr: 'CA',
    format: '(...) ...-....',
  },
  AU: {
    dialCode: '61',
    nationName: 'Australia',
    abbr: 'AU',
    format: '... ... ...',
  },
};

const CountrySelector = ({ value, onChange }) => {
  return (
    <Select value={value} onChange={onChange} dropdownMatchSelectWidth={false}>
      {Object.keys(PhoneCountryMap).map((nation) => (
        <Option key={nation} value={nation}>
          <span style={{ display: 'inline-block', width: 30, textAlign: 'right', marginRight: 8 }}>
            +{PhoneCountryMap[nation].dialCode}
          </span>
          <span>{PhoneCountryMap[nation].nationName}</span>
        </Option>
      ))}
    </Select>
  );
};

const InternationalPhoneInput = (props) => {
  const {
    size = 'middle',
    country = 'US',
    onChange = () => {},
    value = '',
    showCountrySelect = false,
    width = '100%',
    placeholder,
  } = props;

  const ref = useRef();
  const formatterRef = useRef(new AsYouType(country));
  const [phone, setPhone] = useState(undefined);
  const [e164, setE164] = useState(undefined);
  const [isValid, setIsValid] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(() => country);

  // backfill phone data when editing on first time
  useEffect(() => {
    if (value && value !== e164) {
      setE164(e164);
      let formattedNumber;
      if (value.startsWith(`+${PhoneCountryMap[currentCountry].dialCode}`)) {
        formattedNumber = handleMobileChange(value.slice(PhoneCountryMap[currentCountry].dialCode.length + 1));
      } else {
        formattedNumber = handleMobileChange(value);
      }
      handleFormattedPhone(formattedNumber);

      const formatter = formatterRef.current;
      formatter.reset();
      formatter.input(value);
      const number = formatter.getNumber();
      const valid = number ? number.isPossible() : false;
      setIsValid(valid);
      if (!valid && value.includes('*')) {
        setPhone(undefined);
      }
    }
  }, [value]);

  const formatNumber = (text, patternArg) => {
    if (!text || !text.length) return '';

    const formattedObject = reduce(
      patternArg,
      (acc, character) => {
        if (acc.remainingText.length === 0) {
          return acc;
        }
        if (character !== '.') {
          return {
            formattedText: acc.formattedText + character,
            remainingText: acc.remainingText,
          };
        }
        return {
          formattedText: acc.formattedText + head(acc.remainingText),
          remainingText: tail(acc.remainingText),
        };
      },
      {
        formattedText: '',
        remainingText: text.split(''),
      }
    );

    const formattedNumber = formattedObject.formattedText + formattedObject.remainingText.join('');
    // Always close brackets
    // if (formattedNumber.includes('(') && !formattedNumber.includes(')')) formattedNumber += ')'
    return formattedNumber;
  };

  const onNationChange = (nation) => {
    setCurrentCountry(nation);
    const newFormatter = new AsYouType(nation);
    formatterRef.current = newFormatter;
    const unformattedNumber = phone.replace(/\D/g, '');
    const formattedNumber = formatNumber(unformattedNumber, PhoneCountryMap[nation].format);
    handleFormattedPhone(formattedNumber);
  };

  const handleMobileChange = (mobile) => {
    const { format } = PhoneCountryMap[currentCountry];
    const newValue = mobile.replace(/\D/g, '');
    if (newValue.length > 15) return;

    const formattedNumber = formatNumber(newValue, format);
    setPhone(formattedNumber);
    return formattedNumber;
  };

  const onInputChange = (e) => {
    const formattedNumber = handleMobileChange(e.target.value || '');
    handleFormattedPhone(formattedNumber);
  };

  const handleFormattedPhone = (formattedNumber) => {
    const formatter = formatterRef.current;
    formatter.reset();
    const dialCode = (PhoneCountryMap[currentCountry] || {}).dialCode || '';
    formatter.input(`+${dialCode} ${formattedNumber}`);
    const number = formatter.getNumber();
    const phone = number ? number.number : undefined;
    const valid = number ? number.isPossible() : false;
    setIsValid(valid);
    setE164(phone);
    onChange(phone);
  };

  return (
    <Tooltip
      visible={!!phone && !isValid}
      title={<FormattedMessage id="component.phoneInput.invalid" />}
      color="#ce2c54"
      placement="bottom"
    >
      <Input
        ref={ref}
        autoComplete="off"
        size={size}
        addonBefore={
          showCountrySelect ? (
            <CountrySelector value={currentCountry} onChange={onNationChange} />
          ) : (
            `+${PhoneCountryMap[currentCountry].dialCode}`
          )
        }
        value={phone}
        onChange={onInputChange}
        style={{ width }}
        placeholder={placeholder}
      />
    </Tooltip>
  );
};

export default InternationalPhoneInput;
