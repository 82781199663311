import { useMemo } from 'react';
import indexStyles from './index.less';
const UnoptimizedImage = (props) => {
  const webpFileName = useMemo(() => {
    if (!props.src) return '';
    return props.src.substring(0, props.src.lastIndexOf('.')) + '.webp';
  }, [props]);
  const { objectFit, ...others } = props;
  return (
    <div className={indexStyles.unoptimizedImageWrapper}>
      <picture>
        <source type="image/webp" srcSet={`${webpFileName}`} />
        <img
          {...others}
          className={`${indexStyles.unoptimizedImage} ${props.className || ''}`}
          style={objectFit ? { objectFit } : {}}
        />
      </picture>
    </div>
  );
};

export default UnoptimizedImage;
