import React, { useEffect, useMemo } from 'react';
import { Grid } from 'antd';
import generalMenus from '../../consts/generalMenu';
import ExpandMenuHeader from './ExpandMenuHeader';
import CollapsedMenuHeader from './CollapsedMenuHeader';
import styles from './index.less';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../selector/user';
import GoogleTagManager from '../../third_party/GoogleTagManager';
const { useBreakpoint } = Grid;

const GeneralHeader = ({ locale, asPath, simple = false, ownerPageId }) => {
  const screens = useBreakpoint();
  const { currentUser } = useSelector(currentUserSelector);
  const isLogged = !!currentUser;

  const selectedKeys = useMemo(() => {
    return generalMenus.reduce((prev, current) => {
      if (asPath.includes(current.link)) {
        prev.push(current.name);

        if (current.children) {
          const menu = current.children.find((_) => asPath.includes(_.link));
          if (menu) prev.push(menu.name);
        }
      }

      return prev;
    }, []);
  }, [asPath]);

  useEffect(() => {
    window.sessionStorage.setItem('pos_website.start_session', new Date().getTime() + '');

    let timer = null;
    let checkSesessionDuration = () => {
      let start_session = window.sessionStorage.getItem('pos_website.start_session');
      if (start_session && Number.parseInt(start_session)) {
        if (new Date().getTime() - Number.parseInt(start_session) >= 3 * 60 * 1000) {
          GoogleTagManager.trackSessionMorethan3Mins();
          window.sessionStorage.removeItem('pos_website.start_session');
        } else {
          timer = setTimeout(() => {
            checkSesessionDuration();
          }, 1 * 60 * 1000);
        }
      }
    };

    checkSesessionDuration();
  }, []);

  useEffect(() => {
    //collect UTM data
    //https://support.google.com/analytics/answer/1033863?hl=en&ref_topic=1032998&sjid=17274747834548532229-NA#zippy=%2Cin-this-article
    //https://pos.chowbus.com?utm_source=campaignsource&utm_medium=campaignmedium&utm_campaign=campaignname&utm_id=campaignid&utm_term=campaignterm&utm_content=campaigncontent

    const parsedUrl = new URL(window.location.href);
    const searchParams = parsedUrl.searchParams;
    const utm_keys_obj = {
      utm_source: '00N3t00000LJ0Jt',
      utm_medium: '00N3t00000LJ0Jy',
      utm_campaign: '00N3t00000LJ0K3',
      utm_id: '00N3t00000LJ0Jo',
      utm_term: '00N3t00000LJ0K8',
      utm_content: '00N3t00000LJ0KD',
    };

    const existedUTMData = JSON.parse(window.sessionStorage.getItem('pos_website.utm') || '{}');
    const utm_keys = Object.keys(utm_keys_obj);
    const utmData = Array.from(searchParams.keys()).reduce((utmData, key) => {
      if (utm_keys.indexOf(key) !== -1) {
        utmData[utm_keys_obj[key]] = searchParams.get(key);
      }
      return utmData;
    }, existedUTMData);

    window.sessionStorage.setItem('pos_website.utm', JSON.stringify(utmData));
  }, []);

  return (
    <div className={styles.GlobalHeader}>
      {screens.xl || !screens.hasOwnProperty('xl') ? (
        <ExpandMenuHeader
          ownerPageId={ownerPageId}
          locale={locale}
          selectedKeys={selectedKeys}
          isLogged={isLogged}
          simple={simple}
        />
      ) : (
        <CollapsedMenuHeader
          ownerPageId={ownerPageId}
          locale={locale}
          selectedKeys={selectedKeys}
          isLogged={isLogged}
          simple={simple}
        />
      )}
    </div>
  );
};

export default GeneralHeader;
