import React, { useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localeSelector } from 'src/selector/app';
import { useRouter } from 'next/router';
import { switchLocale } from 'src/actions/app';

// const isServer = typeof window === 'undefined';
export default function useLocale() {
  const dispatch = useDispatch();
  const _locale_ = useSelector(localeSelector);
  const router = useRouter();

  const locale = useMemo(() => {
    let locale_f = router.locale || _locale_;
    return locale_f;
  }, [router.locale, _locale_]);

  useEffect(() => {
    if (router.locale) {
      dispatch(switchLocale(router.locale));
    }
  }, []);

  return locale;
}
