import React, { useCallback, useMemo } from 'react';
import { Menu, Button, Row, Col } from 'antd';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import topmenu from 'src/consts/poswebsite_content/topmenu';
import { getItemName } from '../../utils/utils';
import { mixpanel } from '../../third_party/Mixpanel';
import styles from './ExpandMenuHeader.less';
import { switchLocale } from '../../actions/app';
import GoogleTagManager from '../../third_party/GoogleTagManager';
import router from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import { POS_WEB_SITE_CONTEXT_PATH } from 'src/consts/resource';
const CONTEXT_PATH = POS_WEB_SITE_CONTEXT_PATH;

const chowbuslogo = `${process.env.ASSETS_PREFIX}/assets/logo@3x.png`;

const ExpandMenuHeader = ({ locale, selectedKeys, isLogged, simple, ownerPageId }) => {
  const dispatch = useDispatch();
  const generalMenus = useMemo(() => {
    return topmenu[locale];
  }, [locale]);

  const onGetDemoHandler = () => {
    mixpanel.track({ enableTracking: true, name: 'website/clicked_get_a_demo_from_header' });
  };
  const switchDisplayLang = useCallback(() => {
    let targetLocal = locale === 'en' ? 'zh' : 'en';
    GoogleTagManager.trackSwitchLanguge(targetLocal);
    dispatch(switchLocale(targetLocal));

    router.replace(
      {
        pathname: router.pathname,
        query: router.query,
      },
      router.asPath,
      { locale: targetLocal }
    );
  }, [locale]);

  const onLoginRequest = useCallback(() => {
    GoogleTagManager.trackLogin();
    // document.location.href = `/user/login`;
    router.push(`/user/login`);
  }, []);

  return (
    <div className={styles.ExpandMenuHeader}>
      <Row justify="space-between">
        <Col>
          <Link href={`${CONTEXT_PATH}/`} key="logo">
            <a>
              <img src={chowbuslogo} alt="logo" width={171} height={32} title="chowbus logo" style={{ marginTop: 5 }} />
            </a>
          </Link>
        </Col>
        <Col>
          <Row gutter={20}>
            {!simple && (
              <React.Fragment>
                <Col>
                  <Menu mode="horizontal" selectedKeys={selectedKeys} className={styles.ExpandMenu}>
                    {generalMenus.map((menu) => {
                      if (!menu.children) {
                        return (
                          <Menu.Item key={menu.name}>
                            <Link href={menu.link}>
                              <a target={menu.target || '_self'}>{getItemName(menu, locale)}</a>
                            </Link>
                          </Menu.Item>
                        );
                      }

                      return (
                        <Menu.SubMenu key={menu.name} title={menu.name}>
                          {menu.children.map((subMenu) => (
                            <Menu.Item key={subMenu.name}>
                              <Link href={subMenu.link}>
                                <a target={subMenu.target || '_self'}>{getItemName(subMenu, locale)}</a>
                              </Link>
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      );
                    })}
                  </Menu>
                </Col>
                <Col>
                  <Link href={`/get-a-demo${'?source=upper'}`}>
                    <a>
                      <Button type="primary" shape="round" size="large" onClick={onGetDemoHandler}>
                        {locale === 'zh' ? '联系我们' : 'Get a demo'}
                      </Button>
                    </a>
                  </Link>
                </Col>
                <Col>
                  {isLogged ? (
                    <Link href="/dashboard" locale={false}>
                      <a>
                        <Button shape="round" size="large">
                          Dashboard
                        </Button>
                      </a>
                    </Link>
                  ) : (
                    <Button shape="round" size="large" onClick={onLoginRequest}>
                      {locale === 'zh' ? '登录' : 'Login'}
                    </Button>
                  )}
                </Col>
              </React.Fragment>
            )}
            <Col>
              <div className={styles.switchLocalButton} onClick={switchDisplayLang}>
                <span>{locale === 'en' ? '中' : 'EN'}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ExpandMenuHeader;
