/**
 * Google Tag Manager is an interface to Google Analytics and Google Ads
 * It needs to load on every page. The tracking codes are defined on the
 * Google Tag Manager site. Ask help desk for access if you need to add or change any.
 */

function _track(eventName, details = {}) {
  // gtag is a global set up in Google.jsx
  // eslint-disable-next-line no-undef
  gtag('event', eventName, {
    ...details,
  });
}

//   Track the users who start to fill-out the demo form
function trackStartDemoForm() {
  _track('Start Demo Form', {});
}

//   Track the successful submissions to schedule a demo
function trackSubmitRequestDemoForm() {
  _track('Submit Request Demo', {});
}

function trackSubmitReferralForm() {
  _track('Submit Referral Request', {});
}

//   If the session of the user is greater than 3 min
function trackSessionMorethan3Mins() {
  _track('Session Length > 3min', {});
}

// Count the users playing video on page.
function trackPlayVideo() {
  _track('Play Video', {});
}

//   When users login
function trackLogin() {
  _track('Login', {});
}
//   When user toggles between EN/CN
function trackSwitchLanguge(targetLang) {
  _track('Click EN vs CN', {
    lang: targetLang,
  });
}

export default {
  trackStartDemoForm,
  trackSubmitRequestDemoForm,
  trackSessionMorethan3Mins,
  trackPlayVideo,
  trackLogin,
  trackSwitchLanguge,
  trackSubmitReferralForm,
};
