import { useCallback, useEffect, useState } from 'react';
import { getSessionItem, setSessionItem, removeSessionItem } from '../store/storage';

export default function useGoogleAds() {
  let [googleAdsUTMData, setGoogleAdsUTMData] = useState(() => {
    let googleAdsOnSession = getSessionItem('__googleAds__');
    let googleAdsOnSessionObj = null;
    if (googleAdsOnSession) {
      googleAdsOnSessionObj = JSON.parse(googleAdsOnSession);
    }
    return googleAdsOnSessionObj;
  });

  const updateDemoPopupShown = useCallback(
    ({ closed = false } = {}) => {
      if (googleAdsUTMData) {
        let _new_data = {
          ...googleAdsUTMData,
          shown: true,
          closed,
        };
        setSessionItem({ __googleAds__: _new_data });
        setGoogleAdsUTMData(_new_data);
      }
    },
    [googleAdsUTMData]
  );

  useEffect(() => {
    let googleAdsOnSession = getSessionItem('__googleAds__');
    if (googleAdsOnSession) {
      try {
        let googleAdsOnSessionObj = JSON.parse(googleAdsOnSession);
        if (googleAdsOnSessionObj) {
          if (
            googleAdsOnSessionObj['utm_source'] === 'google' &&
            googleAdsOnSessionObj['utm_medium'] === 'cpc' &&
            googleAdsOnSessionObj['utm_campaign'] === 'search_campaign'
          ) {
            setGoogleAdsUTMData({
              ...googleAdsOnSessionObj,
            });
          } else {
            setGoogleAdsUTMData(null);
            removeSessionItem('__googleAds__');
          }
        }
      } catch {
        setGoogleAdsUTMData(null);
        removeSessionItem('__googleAds__');
      }
    } else {
      let urlSearchParams = new URLSearchParams(new URL(document.location.href).search);
      if (
        urlSearchParams.get('utm_source') === 'google' &&
        urlSearchParams.get('utm_medium') === 'cpc' &&
        urlSearchParams.get('utm_campaign') === 'search_campaign'
      ) {
        let googleAdsUTMData = {
          device: urlSearchParams.get('device') || '',
          matchtype: urlSearchParams.get('matchtype') || '',
          utm_source: urlSearchParams.get('utm_source') || '',
          utm_medium: urlSearchParams.get('utm_medium') || '',
          utm_campaign: urlSearchParams.get('utm_campaign') || '',
          utm_term: urlSearchParams.get('utm_term') || '',
          shown: false,
          closed: false,
        };
        setGoogleAdsUTMData(googleAdsUTMData);
        setSessionItem({ __googleAds__: googleAdsUTMData });
      } else {
        setGoogleAdsUTMData(null);
        removeSessionItem('__googleAds__');
      }
    }
    return () => {};
  }, []);

  return {
    googleAdsUTMData,
    updateDemoPopupShown,
  };
}
