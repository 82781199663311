import React, { useCallback, useRef, useState } from 'react';
import { Button, Col, Form, Row, Spin } from 'antd';
import { FloatLabelInput, FloatLabelPhoneInput, FloatLabelSelect, FloatLabelOption } from '../FloatLabel';
import styles from './common.less';
import { setSessionItem } from '../../store/storage';
import { mixpanel } from '../../third_party/Mixpanel';
import { FormattedMessage, useIntl } from 'react-intl';
import GoogleTagManager from '../../third_party/GoogleTagManager';
import { FBPixelEventTracker } from 'src/third_party/FacebookPixel';
import useLocale from 'src/hooks/useLocale';
import { sendGetADemoRequestToSF, sendReferralRequestToSF } from 'src/services/get-a-demo';
import useGoogleAds from 'src/hooks/googleAdsHook';

const DEBUG_EMAL = 'joel.stump@chowbus.com';
const REFERRAL_DEBUG_EMAIL = 'daiping.yang@chowbus.com';
const REDIRECT_URL = 'http://pos.chowbus.com';

const hearAboutChowbusOptions = [
  {
    zh: '谷歌',
    en: 'Google',
    value: 'Google',
  },
  {
    zh: '朋友推荐',
    en: 'Friends',
    value: 'Friends',
  },
  {
    zh: '在餐厅中看到chowbus产品',
    en: 'Saw our product in a restaurant',
    value: 'Saw our product in a restaurant',
  },
  {
    zh: '社交媒体',
    en: 'Social Media',
    value: 'Social Media',
  },
  {
    zh: '其他',
    en: 'Others',
    value: 'Others',
  },
];

export const ContactForm = ({
  nextStep,
  showSkipNow,
  onSkip,
  triggerSource,
  leadSource = 'Inbound Website (POS)',
  submitButtonKey,
}) => {
  const [form] = Form.useForm();
  const locale = useLocale();
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUserInteraction, setHasUserInteraction] = useState(false);
  const { googleAdsUTMData } = useGoogleAds();

  if (googleAdsUTMData) {
    leadSource = 'Google Ads';
  }
  const onFinish = async () => {
    const existedUTMData = JSON.parse(window.sessionStorage.getItem('pos_website.utm') || '{}');
    let urlSearchParams = new URLSearchParams(new URL(document.location.href).search);
    let submitSource = urlSearchParams.get('source') || triggerSource || '';

    let formValues = form.getFieldValue();
    //mixpanel event
    const traceEventProperties = {};
    Object.keys(formValues).forEach((key) => {
      traceEventProperties[key] = formValues[key] || '';
    });
    traceEventProperties['email'] = '***';
    traceEventProperties['phone'] = '***';
    traceEventProperties['retURL'] = REDIRECT_URL;

    let existedGoogleAdsUTMData = {
      '00NQq0000016vCj': '', //utm_source
      '00NQq0000016vEL': '', //utm_medium
      '00NQq0000016vFx': '', //utm_campaign
      '00NQq0000016vO1': submitSource || '', // source
    };
    if (googleAdsUTMData) {
      existedGoogleAdsUTMData = {
        ...existedGoogleAdsUTMData,
        '00NQq0000016vCj': googleAdsUTMData['utm_source'] || '',
        '00NQq0000016vEL': googleAdsUTMData['utm_medium'] || '',
        '00NQq0000016vFx': googleAdsUTMData['utm_campaign'] || '',
      };
    }

    try {
      setIsSubmitting(true);
      let existedUTMDataObj = Object.keys(existedUTMData).reduce((result, key) => {
        let value = existedUTMData[key];
        result[key] = value;
        return result;
      }, {});

      let { state_: _state_, phone_: _phone_, ...oterhFormValues } = formValues;
      mixpanel.track({
        enableTracking: true,
        name: 'website/salesforce_WebToLead',
        properties: {
          ...traceEventProperties,
        },
      });
      GoogleTagManager.trackSubmitRequestDemoForm();
      FBPixelEventTracker.trackGetADemo(leadSource, {
        leadSource,
        ...oterhFormValues,
        state: _state_,
        phone: _phone_,
      });

      setSessionItem({
        show_get_pos_demo_initiativly: 'no',
      });

      const submitData = {
        ...oterhFormValues,
        state: _state_,
        phone: _phone_,
        lead_source: leadSource,
        retURL: REDIRECT_URL,
        ...existedUTMDataObj,
        ...existedGoogleAdsUTMData,
        ...(process.env.STAGING_ENV !== 'production'
          ? {
              debug: '1',
              debugEmail: DEBUG_EMAL,
            }
          : {}),
      };
      let response = await sendGetADemoRequestToSF(submitData);
      setIsSubmitting(false);

      if (response.success) {
        mixpanel.track({
          enableTracking: true,
          name: 'website/salesforce_WebToLead/success',
          properties: {
            ...traceEventProperties,
          },
        });
      } else {
        mixpanel.track({
          enableTracking: true,
          name: 'website/salesforce_WebToLead/failed',
          properties: {
            ...traceEventProperties,
          },
        });
      }
      nextStep();
    } catch (e) {
      console.error('E', e);
      setIsSubmitting(false);
      mixpanel.track({
        enableTracking: true,
        name: 'website/salesforce_WebToLead/exception',
        properties: {
          ...traceEventProperties,
        },
      });
    }
  };

  const onValuesChanged = () => {
    if (!hasUserInteraction) {
      setHasUserInteraction(true);
      mixpanel.track({
        enableTracking: true,
        name: 'website/salesforce_WebToLead/start_to_fill_out',
      });
    }
  };

  return (
    <Form
      onValuesChange={onValuesChanged}
      form={form}
      size="large"
      disabled={isSubmitting}
      method="POST"
      onFinish={onFinish}
    >
      {/*NOTE: These fields are optional debugging elements. Please uncomment hese lines if you wish to test in debug mode.*/}
      {process.env.STAGING_ENV !== 'production' && (
        <React.Fragment>
          <input type="hidden" name="debug" value="1" />
          <input type="hidden" name="debugEmail" value={DEBUG_EMAL} />
        </React.Fragment>
      )}
      <input type="hidden" name="lead_source" value={leadSource} />
      <input type="hidden" name="retURL" value={REDIRECT_URL} />
      <input type="hidden" name="state" value="" />
      <input type="hidden" name="phone" value="" />

      <Row gutter={28}>
        <Col xs={24} xl={12}>
          <Form.Item name="first_name" rules={[{ required: true, message: 'required' }]} help={null}>
            <FloatLabelInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_maker_first_name' })}
              name="first_name"
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="last_name" rules={[{ required: true, message: 'required' }]} help={null}>
            <FloatLabelInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_maker_last_name' })}
              name="last_name"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="phone_" rules={[{ required: true, message: 'required' }]} help={null}>
            <FloatLabelPhoneInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_mobile' })}
              name="phone_"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'required' }]} help={null}>
            <FloatLabelInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_email_1' })}
              name="email"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="company" rules={[{ required: true, message: 'required' }]} help={null}>
            <FloatLabelInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_business_name_1' })}
              name="company"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="zip"
            rules={[
              { required: true, max: 20, type: 'string', message: 'required' },
              { max: 20, type: 'string', pattern: new RegExp('^\\d{5}$'), message: 'invalid Zip Code' },
            ]}
            help={null}
          >
            <FloatLabelInput
              // label="Zip Code*"
              label={intl.formatMessage({ id: 'home.referral.form.restaurant_zip_1' })}
              name="zip"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Row gutter={[16, 32]} align="center">
            <Col xs={24} lg={18}>
              <Button type="primary" htmlType="submit" className="action-button">
                <FormattedMessage id={`${submitButtonKey || 'home.book.pos.demo.button'}`} />
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row gutter={[16, 32]} align="center">
            <Col xs={24} lg={24}>
              <p className={'actionSubTitle'}>
                <FormattedMessage id="get-a-demo-from-privacy" />
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const Step1 = (props) => {
  let { title, subTitle, triggerSource } = props;
  const locale = useLocale();
  let intersectionObserverRef = useRef(null);
  const demoFormInstrocution = demoInstrocution[locale];

  const getADemoRef = useCallback((node) => {
    const threshold = 0.01;
    if (node) {
      try {
        let options = {
          root: null,
          rootMargin: '0px',
          threshold: threshold,
        };

        let observer = new IntersectionObserver((entries) => {
          let entry = entries[0];
          if (entry && entry.isIntersecting && entry.intersectionRatio >= threshold) {
            GoogleTagManager.trackStartDemoForm();
            mixpanel.track({
              enableTracking: true,
              name: 'website/Start Demo Form',
            });
          }
        }, options);
        observer.observe(node);
        intersectionObserverRef.current = observer;
      } catch (e) {
        intersectionObserverRef.current?.disconnect();
        intersectionObserverRef.current = null;
      }
    } else {
      intersectionObserverRef.current?.disconnect();
      intersectionObserverRef.current = null;
    }
  }, []);

  return (
    <div className={`${styles.StepContainer} ${styles.StepContainerForHome}`}>
      <div className="pos-poster">
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <picture>
            <source type="image/webp" srcSet={`${process.env.ASSETS_PREFIX}/Key-photo-min.webp`} />
            {/* <source type="image/webp" srcSet={`${process.env.ASSETS_PREFIX}/3_in_1_smart_ordering.webp`} /> */}
            <img
              className="client-3-in-1-image-2"
              src={`${process.env.ASSETS_PREFIX}/Key-photo-min.png`}
              style={{ maxWidth: '100%' }}
              alt="Chowbus POS Poster"
            />
          </picture>
        </div>
      </div>
      <div className="pos-description">
        <div className="pos-description-inner" ref={getADemoRef} style={{ backgroundColor: '#ffffff' }}>
          <div style={{ marginBottom: '24px' }} className="top-emphasis-description">
            {demoFormInstrocution.top.map(({ title, subTitle }, index) => {
              return (
                <React.Fragment key={index}>
                  {
                    <span
                      className="emphasis-header"
                      style={
                        index === 0
                          ? { marginTop: 0, display: 'block', fontWeight: 700 }
                          : { display: 'block', fontWeight: 700 }
                      }
                    >
                      {title}
                    </span>
                  }
                  {
                    <React.Fragment>
                      {subTitle.map((item, index) => {
                        return (
                          <p key={index} className="withCheckbox">
                            <img
                              src={`${process.env.ASSETS_PREFIX}/checkbox.png`}
                              alt={''}
                              style={{ width: `22px`, maxHeight: '100%', marginRight: '8px' }}
                            />
                            <span dangerouslySetInnerHTML={{ __html: item }}></span>
                          </p>
                        );
                      })}
                    </React.Fragment>
                  }
                </React.Fragment>
              );
            })}
          </div>
          <ContactForm {...props} />
        </div>
      </div>
    </div>
  );
};

const demoInstrocution = {
  zh: {
    leftLogoWalletTitle: '深受全美各种规模和菜系的餐厅信赖',
    top: [
      {
        title: '专为餐厅定制的POS系统',
        subTitle: [
          '为您提供一站式餐饮解决方案',
          '行业领先的智能点餐方案帮您节省人工 (-$8000)、增加效率、提升营业额(+25%)',
          '24/7小时及时的售后服务加上上门安装培训保障您的无忧切换',
          '马上填表预约咨询，领取<span style="color:#e00051">上千美金优惠大礼包！</span>',
        ],
      },
      // {
      //   title: '欢迎咨询销售顾问，获取产品介绍',
      //   subTitle: ['请填写表格，我们将在24小时内与您联系，安排免费的产品演示。'],
      // },
    ],
  },
  en: {
    leftLogoWalletTitle: 'Trusted by top restaurants in the US',
    top: [
      {
        title: 'POS Systems Built for Restaurants',
        subTitle: [
          'All in 1 POS Management Suite - No need for multiple systems',
          'Smart Ordering solutions - Save labor (-$8000), increase efficiency and grow sales(+25%)',
          '24/7 Instant Customer Support - Onsite installation & training, shift with ease',
          'Book a demo now and claim your <span style="color:#e00051">exclusive discount package worth thousands!</span>',
        ],
      },
      // {
      //   title: 'Curious about Chowbus POS?',
      //   subTitle: ['Fill out the form and we’ll reach out within 24 hours to schedule a free, no-obligation demo'],
      // },
    ],
  },
};

export const ContactReferralForm = ({ nextStep, showSkipNow, onSkip, leadSource = 'referral form' }) => {
  const [form] = Form.useForm();
  const locale = useLocale();
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const requiredRule = { required: true, message: intl.formatMessage({ id: 'home.referral.form.field.required' }) };
  const { googleAdsUTMData } = useGoogleAds();

  const onFinish = async () => {
    const existedUTMData = JSON.parse(window.sessionStorage.getItem('pos_website.utm') || '{}');
    let urlSearchParams = new URLSearchParams(new URL(document.location.href).search);
    let submitSource = urlSearchParams.get('source') || '';

    if (googleAdsUTMData) {
      leadSource = 'Google Ads';
    }

    let formValues = form.getFieldValue();
    //mixpanel event
    const traceEventProperties = {};
    Object.keys(formValues).forEach((key) => {
      traceEventProperties[key] = formValues[key] || '';
    });
    traceEventProperties['email'] = '***';
    traceEventProperties['phone'] = '***';
    traceEventProperties['retURL'] = REDIRECT_URL;

    try {
      setIsSubmitting(true);
      let existedUTMDataObj = Object.keys(existedUTMData).reduce((result, key) => {
        let value = existedUTMData[key];
        result[key] = value;
        return result;
      }, {});

      let existedGoogleAdsUTMData = {
        '00NQq0000016vCj': '', //utm_source
        '00NQq0000016vEL': '', //utm_medium
        '00NQq0000016vFx': '', //utm_campaign
        '00NQq0000016vO1': submitSource || '', // source
      };
      if (googleAdsUTMData) {
        existedGoogleAdsUTMData = {
          ...existedGoogleAdsUTMData,
          '00NQq0000016vCj': googleAdsUTMData['utm_source'] || '',
          '00NQq0000016vEL': googleAdsUTMData['utm_medium'] || '',
          '00NQq0000016vFx': googleAdsUTMData['utm_campaign'] || '',
        };
      }

      let {
        state_: _state_,
        //00NQq000000C86f
        referral_mobile_phone_: _referral_mobile_phone_,
        //mobile
        recommend_restaurant_person_mobile_phone_: _recommend_restaurant_person_mobile_phone_,

        ...oterhFormValues
      } = formValues;
      mixpanel.track({
        enableTracking: true,
        name: 'website/salesforce_referral_form',
        properties: {
          ...traceEventProperties,
        },
      });
      GoogleTagManager.trackSubmitRequestDemoForm();

      setSessionItem({
        show_get_pos_demo_initiativly: 'no',
      });

      let submitDataObj = {
        ...oterhFormValues,
        '00NQq000000FF6n': '1', //External Referral
        '00NQq000000GAD7': locale === 'en' ? 'EN Landing Page' : 'CN Landing Page', //Marketing Landing Page: CN Landing Page /EN Landing Page
        '00NQq000000C86f': _referral_mobile_phone_,
        last_name: oterhFormValues.first_name,
        phone: _recommend_restaurant_person_mobile_phone_,
        mobile: _recommend_restaurant_person_mobile_phone_,
        lead_source: leadSource,
        retURL: REDIRECT_URL,
        ...existedUTMDataObj,
        ...existedGoogleAdsUTMData,
        ...(process.env.STAGING_ENV !== 'production'
          ? {
              debug: '1',
              debugEmail: REFERRAL_DEBUG_EMAIL,
            }
          : {}),
      };
      FBPixelEventTracker.trackGetADemo(leadSource, { leadSource, ...submitDataObj });

      let response = await sendReferralRequestToSF(submitDataObj);
      setIsSubmitting(false);

      if (response.success) {
        mixpanel.track({
          enableTracking: true,
          name: 'website/salesforce_referral_form/success',
          properties: {
            ...traceEventProperties,
          },
        });
      } else {
        mixpanel.track({
          enableTracking: true,
          name: 'website/salesforce_referral_form/failed',
          properties: {
            ...traceEventProperties,
          },
        });
      }
      nextStep();
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
      mixpanel.track({
        enableTracking: true,
        name: 'website/salesforce_referral_form/exception',
        properties: {
          ...traceEventProperties,
        },
      });
    }
  };

  return (
    <Form form={form} size="large" disabled={isSubmitting} method="POST" onFinish={onFinish}>
      {/*NOTE: These fields are optional debugging elements. Please uncomment hese lines if you wish to test in debug mode.*/}
      {process.env.STAGING_ENV !== 'production' && (
        <React.Fragment>
          <input type="hidden" name="debug" value="1" />
          <input type="hidden" name="debugEmail" value={REFERRAL_DEBUG_EMAIL} />
        </React.Fragment>
      )}
      <input type="hidden" name="lead_source" value={leadSource} />
      <input type="hidden" name="retURL" value={REDIRECT_URL} />

      <Row gutter={28}>
        <Col xs={24} xl={24}>
          <Form.Item name="00NQq000000C88H" rules={[requiredRule]} help={null}>
            <FloatLabelInput
              maxLength={250}
              label={intl.formatMessage({ id: 'home.referral.form.referral_person_name' })}
              name="00NQq000000C88H"
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24}>
          <Form.Item name="00NQq000000C83R" rules={[requiredRule]} help={null}>
            <FloatLabelInput
              maxLength={250}
              label={intl.formatMessage({ id: 'home.referral.form.referral_restaurant_name' })}
              name="00NQq000000C83R"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          {/* 00NQq000000C86f */}
          <Form.Item name="referral_mobile_phone_" rules={[requiredRule]} help={null}>
            <FloatLabelPhoneInput
              label={intl.formatMessage({ id: 'home.referral.form.referral_mobile' })}
              name="referral_mobile_phone_"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="00NQq000000Ewwz" rules={[{ required: false, message: 'required' }]} help={null}>
            <FloatLabelInput
              maxLength={100}
              label={intl.formatMessage({ id: 'home.referral.form.referral_restaurant_sale' })}
              name="00NQq000000Ewwz"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="company" rules={[requiredRule]} help={null}>
            <FloatLabelInput
              maxLength={40}
              label={intl.formatMessage({ id: 'home.referral.form.recommend_restaurant_name' })}
              name="company"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item name="city" rules={[requiredRule]} help={null}>
            <FloatLabelInput
              maxLength={40}
              label={intl.formatMessage({ id: 'home.referral.form.recommend_restaurant_location' })}
              name="city"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item name="first_name" rules={[requiredRule]} help={null}>
            <FloatLabelInput
              maxLength={40}
              label={intl.formatMessage({ id: 'home.referral.form.recommend_restaurant_contract_person_name' })}
              name="first_name"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item name="recommend_restaurant_person_mobile_phone_" rules={[requiredRule]} help={null}>
            <FloatLabelPhoneInput
              label={intl.formatMessage({ id: 'home.referral.form.recommend_restaurant_contract_person_mobile' })}
              name="recommend_restaurant_person_mobile_phone_"
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Row gutter={[16, 32]} align="center">
            <Col xs={24} lg={18}>
              <Button type="primary" htmlType="submit" className="action-button">
                {/* Next Step */}
                {/* Submit */}
                <FormattedMessage id="home.book.pos.referral.button" />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Step1;

export const GetADemoOnTop = (props) => {
  let { title, subTitle, triggerSource } = props;
  const locale = useLocale();
  let intersectionObserverRef = useRef(null);
  const demoFormInstrocution = demoInstrocution[locale];

  const getADemoRef = useCallback((node) => {
    const threshold = 0.01;
    if (node) {
      try {
        let options = {
          root: null,
          rootMargin: '0px',
          threshold: threshold,
        };

        let observer = new IntersectionObserver((entries) => {
          let entry = entries[0];
          if (entry && entry.isIntersecting && entry.intersectionRatio >= threshold) {
            GoogleTagManager.trackStartDemoForm();
            mixpanel.track({
              enableTracking: true,
              name: 'website/Start Demo Form',
            });
          }
        }, options);
        observer.observe(node);
        intersectionObserverRef.current = observer;
      } catch (e) {
        intersectionObserverRef.current?.disconnect();
        intersectionObserverRef.current = null;
      }
    } else {
      intersectionObserverRef.current?.disconnect();
      intersectionObserverRef.current = null;
    }
  }, []);

  return (
    <div className={`${styles.StepContainer} ${styles.StepContainerForHome}`} style={{ padding: 0 }}>
      <div className="pos-description" style={{ padding: 0, backgroundColor: '#F5F5F5', borderRadius: 16 }}>
        <div
          className="pos-description-inner"
          ref={getADemoRef}
          style={{ backgroundColor: '#F5F5F5', padding: '20px 20px 30px 20px' }}
        >
          <div style={{ marginBottom: '24px' }} className="top-emphasis-description">
            <span
              className="emphasis-header"
              style={{ marginTop: 0, display: 'block', fontWeight: 700, color: '#1D1B2E', textAlign: 'center' }}
            >
              <FormattedMessage id={`top.products.business.book.pos.demo.title`} />
            </span>
          </div>
          <ContactFormWhithShortLayout {...props} submitButtonKey={'top.products.business.book.pos.demo.button'} />
        </div>
      </div>
    </div>
  );
};

export const ContactFormWhithShortLayout = ({
  nextStep,
  showSkipNow,
  onSkip,
  triggerSource,
  leadSource = 'Inbound Website (POS)',
  submitButtonKey,
}) => {
  const [form] = Form.useForm();
  const locale = useLocale();
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUserInteraction, setHasUserInteraction] = useState(false);
  const { googleAdsUTMData } = useGoogleAds();

  if (googleAdsUTMData) {
    leadSource = 'Google Ads';
  }
  const onFinish = async () => {
    const existedUTMData = JSON.parse(window.sessionStorage.getItem('pos_website.utm') || '{}');
    let urlSearchParams = new URLSearchParams(new URL(document.location.href).search);
    let submitSource = urlSearchParams.get('source') || triggerSource || '';

    let formValues = form.getFieldValue();
    //mixpanel event
    const traceEventProperties = {};
    Object.keys(formValues).forEach((key) => {
      traceEventProperties[key] = formValues[key] || '';
    });
    traceEventProperties['email'] = '***';
    traceEventProperties['phone'] = '***';
    traceEventProperties['retURL'] = REDIRECT_URL;

    let existedGoogleAdsUTMData = {
      '00NQq0000016vCj': '', //utm_source
      '00NQq0000016vEL': '', //utm_medium
      '00NQq0000016vFx': '', //utm_campaign
      '00NQq0000016vO1': submitSource || '', // source
    };
    if (googleAdsUTMData) {
      existedGoogleAdsUTMData = {
        ...existedGoogleAdsUTMData,
        '00NQq0000016vCj': googleAdsUTMData['utm_source'] || '',
        '00NQq0000016vEL': googleAdsUTMData['utm_medium'] || '',
        '00NQq0000016vFx': googleAdsUTMData['utm_campaign'] || '',
      };
    }

    try {
      setIsSubmitting(true);
      let existedUTMDataObj = Object.keys(existedUTMData).reduce((result, key) => {
        let value = existedUTMData[key];
        result[key] = value;
        return result;
      }, {});

      let { state_: _state_, phone_: _phone_, ...oterhFormValues } = formValues;
      mixpanel.track({
        enableTracking: true,
        name: 'website/salesforce_WebToLead',
        properties: {
          ...traceEventProperties,
        },
      });
      GoogleTagManager.trackSubmitRequestDemoForm();
      FBPixelEventTracker.trackGetADemo(leadSource, {
        leadSource,
        ...oterhFormValues,
        state: _state_,
        phone: _phone_,
      });

      setSessionItem({
        show_get_pos_demo_initiativly: 'no',
      });

      const submitData = {
        ...oterhFormValues,
        state: _state_,
        phone: _phone_,
        lead_source: leadSource,
        retURL: REDIRECT_URL,
        ...existedUTMDataObj,
        ...existedGoogleAdsUTMData,
        ...(process.env.STAGING_ENV !== 'production'
          ? {
              debug: '1',
              debugEmail: DEBUG_EMAL,
            }
          : {}),
      };
      let response = await sendGetADemoRequestToSF(submitData);
      setIsSubmitting(false);

      if (response.success) {
        mixpanel.track({
          enableTracking: true,
          name: 'website/salesforce_WebToLead/success',
          properties: {
            ...traceEventProperties,
          },
        });
      } else {
        mixpanel.track({
          enableTracking: true,
          name: 'website/salesforce_WebToLead/failed',
          properties: {
            ...traceEventProperties,
          },
        });
      }
      nextStep();
    } catch (e) {
      console.error('E', e);
      setIsSubmitting(false);
      mixpanel.track({
        enableTracking: true,
        name: 'website/salesforce_WebToLead/exception',
        properties: {
          ...traceEventProperties,
        },
      });
    }
  };

  const onValuesChanged = () => {
    if (!hasUserInteraction) {
      setHasUserInteraction(true);
      mixpanel.track({
        enableTracking: true,
        name: 'website/salesforce_WebToLead/start_to_fill_out',
      });
    }
  };

  return (
    <Form
      onValuesChange={onValuesChanged}
      form={form}
      size="large"
      disabled={isSubmitting}
      method="POST"
      onFinish={onFinish}
    >
      {/*NOTE: These fields are optional debugging elements. Please uncomment hese lines if you wish to test in debug mode.*/}
      {process.env.STAGING_ENV !== 'production' && (
        <React.Fragment>
          <input type="hidden" name="debug" value="1" />
          <input type="hidden" name="debugEmail" value={DEBUG_EMAL} />
        </React.Fragment>
      )}
      <input type="hidden" name="lead_source" value={leadSource} />
      <input type="hidden" name="retURL" value={REDIRECT_URL} />
      <input type="hidden" name="state" value="" />
      <input type="hidden" name="phone" value="" />

      <Row gutter={28}>
        <Col xs={24} xl={12}>
          <Form.Item name="first_name" rules={[{ required: true, message: 'required' }]} help={null}>
            <FloatLabelInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_maker_first_name' })}
              name="first_name"
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="last_name" rules={[{ required: true, message: 'required' }]} help={null}>
            <FloatLabelInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_maker_last_name' })}
              name="last_name"
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="phone_" rules={[{ required: true, message: 'required' }]} help={null}>
            <FloatLabelPhoneInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_mobile' })}
              name="phone_"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'required' }]} help={null}>
            <FloatLabelInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_email_1' })}
              name="email"
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="company" rules={[{ required: true, message: 'required' }]} help={null}>
            <FloatLabelInput
              label={intl.formatMessage({ id: 'home.referral.form.referring_restaurant_business_name_1' })}
              name="company"
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item
            name="zip"
            rules={[
              { required: true, max: 20, type: 'string', message: 'required' },
              { max: 20, type: 'string', pattern: new RegExp('^\\d{5}$'), message: 'invalid Zip Code' },
            ]}
            help={null}
          >
            <FloatLabelInput
              // label="Zip Code*"
              label={intl.formatMessage({ id: 'home.referral.form.restaurant_zip_1' })}
              name="zip"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Row gutter={[16, 32]} align="center">
            <Col xs={24} lg={16}>
              <Button type="primary" htmlType="submit" className="action-button">
                <FormattedMessage id={`${submitButtonKey || 'home.book.pos.demo.button'}`} />
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row gutter={[16, 32]} align="center">
            <Col xs={24} lg={24}>
              <p className={'actionSubTitle'}>
                <FormattedMessage id="get-a-demo-from-privacy" />
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
