export const chowbus_one_video_id = 'TBYv_43UoKA';
export const chowbus_qr_code_ordering_video_id = {
  en: '1KahZOUrXFA',
  zh: 'kqq6ihODGkw',
};
('LRzYVozJZyk');
export const chowbus_kiosk_video_id = {
  en: 'N7K1gMddYds',
  zh: '9nM8_So5Whw',
};
('uygdn7eYNj4');
export const shinya_ramen_testimonial_video_id = 'HccE32BVvzg';
export const teamo_boba_tea_video_id = 'FNDAfRHUp70';
export const mrs_gu_video_id = '3ctvsPY4pl0';
export const hunan_cuisine_video_id = '';
export const point_of_sale_video_id = {
  en: '_bJUrVG2DZk',
  zh: 'kOcVcIvgpj8',
};
export const pos_handheld_video_id = {
  en: 'BPTnW0giGLE',
  zh: 'UKfYfMcYnnY',
};

export const tablet_ordering_video_id = {
  en: 'KoBE1_gdZbA',
  zh: 'RcqwDWyzfUg',
};
('KI-mp5kQaXU');

export const loyalty_video_id = {
  en: 'seJg5rn-WUA',
  zh: 'Uwt8XjlQh9I',
};
export const online_ordering_video_id = {
  en: 'GZoPrqxE2lg',
  zh: 'axWxcYJ6ejc',
};
('QkZTp3e1PZ8');
export const thirdparty_integration_video_id = {
  en: 'uX3YLw3LYEc',
  zh: 'Uu_bQJiYaaI',
};

export const POS_WEB_SITE_CONTEXT_PATH = '';

export const waitlist_video_id = {
  en: 'qcMp8D4PO_Q',
  zh: 'a3LEljigl3M',
};
